<template>
	<div id="edit_links">
		<div class="container">
			<div class="col_left">
				<Left></Left>
			</div>
			<div class="col_right">
				<div class="title">
					<h3>添加友情链接</h3>
					<p>当前位置：<router-link to="/user_center/zc_message">会员中心</router-link> / 主页设置 / 添加友情链接</p>
				</div>
				<div class="main">
					<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="标题" prop="name" :rules="[{ required: true, message: '标题不能为空'}]">
							<el-input v-model="ruleForm.name" style="width: 380px;"></el-input>
							<span class="textint">20个字符以内</span>
						</el-form-item>
						<el-form-item label="url" prop="link" :rules="[{ required: true, message: 'url不能为空'}]">
							<el-input v-model="ruleForm.link" style="width: 380px;"></el-input>
							<span class="textint">以http://或https://开头</span>
						</el-form-item>
						<el-form-item label="排序" prop="sort" :rules="[{ required: true, message: '排序不能为空'}]">
							<el-input v-model="ruleForm.sort" style="width: 110px;"></el-input>
							<span class="textint">数字越小排序越靠前</span>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "../company/Left.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			Left
		},
		data() {
			return {
				company: {},
				ruleForm: {
					company_id: '',
					name: '',
					link: '',
					sort: ''
				},
			}
		},
		mounted() {
			this.get_detail();
			let query = this.$route.query;
			if(typeof(query.row) != 'undefined'){
				this.ruleForm = query.row;
			}
		},
		computed: {
			...mapGetters(['user'])
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.ruleForm)
						if(this.ruleForm.id){
							this.$http_qm.post('company_link/update' , this.ruleForm).then(res => {
								if (res.code == 200) {
									this.$message({
										message: '提交成功！',
										type: 'success'
									});
									this.$router.go(-1);
								}
							}).catch(err => {
								console.log(err);
							})
						}else{
							this.$http_qm.post('company_link/add' , this.ruleForm).then(res => {
								if (res.code == 200) {
									this.$message({
										message: '提交成功！',
										type: 'success'
									});
									this.$router.go(-1);
								}
							}).catch(err => {
								console.log(err);
							})
						}
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			get_detail() {
				this.$http_qm.post('company/detail', {
					type: 'me'
				}).then(res => {
					if (res.code == 200) {
						this.company = res.data;
						this.ruleForm.company_id = this.company.id;
					}
				}).catch(err => {
					console.log(err);
				})
			},
		}
	}
</script>

<style lang="less">
	#edit_links {
		background: #f7f7f7 !important;

		.container {
			width: 1200px;
			margin: auto;
			padding: 20px 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.col_left {
				width: 230px;
				border: 1px solid #e6e6e6;
				background: #fff;
				border-top: none;
			}

			.col_right {
				width: 940px;
				border: 1px solid #e6e6e6;
				background: #fff;
				box-sizing: border-box;

				.title {
					height: 60px;
					padding: 0 20px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					h3 {
						line-height: 60px;
						font-size: 16px;
						color: #333;
					}

					p {
						line-height: 60px;
						font-size: 12px;
						color: #999;
					}
				}

				.main {
					padding: 10px 20px 40px;
					border-top: 1px solid #e6e6e6;
					position: relative;
					z-index: 1;

					.textint {
						color: #999;
						font-size: 14px;
						margin-left: 10px;
						line-height: 36px;
					}
				}

			}
		}
	}
</style>
